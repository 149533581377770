import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
// import { Button } from '@material-ui/core';
import { Button } from 'react-bootstrap';
// import UserTable from './UserTable';
const axios = require('axios');

const ExportCSV = (props) => {

    const locid = props.locid;
    const yyyy = props.yyyy;
    const mmNum = props.mmNum;
    const locname = props.locname;

    const headers = {
        'Api-Token': process.env.REACT_APP_API_KEY
    };

    const fileName = "Bonnetid_" + locname + "_" + yyyy + "-" + mmNum;
    const [ptData, setPTData] = useState([]);
    const [loading, setLoading] = useState(false);

    const headers_table = [
        { label: "Dato", key: "date" },
        { label: "Hijri-dato", key: "hijri_date" },
        { label: "Fajr", key: "fajr" },
        { label: "Fajr slutt", key: "fajr_endtime" },
        { label: "Duhr", key: "duhr" },
        { label: "Asr", key: "asr" },
        { label: "1x-skygge", key: "asr_1x_shadow" },
        { label: "2x-skygge", key: "asr_2x_shadow" },
        { label: "Maghrib", key: "maghrib" },
        { label: "Isha", key: "isha" }
    ];

    useEffect(() => {
        getPTData();
    }, [locid, mmNum, yyyy]);

    const getPTData = async () => {
        setLoading(true);
        try {
            const { data: response } = await axios.get('https://api.bonnetid.no//prayertimes/' + locid + '/' + String(yyyy) + '/' + mmNum, { headers });            
            setPTData(response);
            // console.log(response)
        } catch (error) {
            console.log(error)
            //console.error(error.message);
        }
        setLoading(false);
    }

    return (
        <div className='container'>
            <Button
                variant="contained"
                color="primary"
                className='export-btn mt-0 pt-0'
            >
                <CSVLink
                    headers={headers_table}
                    data={ptData}
                    filename={fileName}
                    style={{ "textDecoration": "none", "color": "#fff" }}
                >
                    {loading ? 'Loading csv...' : 'Eksporter månedsdata'}
                </CSVLink>
            </Button>

            {/* <UserTable headers={headers} userData={userData} /> */}
        </div>
    );
}

export default ExportCSV;