import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Col, Row, Card, Image, ListGroup, ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Plot from 'react-plotly.js';

import logo from '../imgs/IRN-logo-2020-1-2-275x300.png'
//import Locations from './Locations';
import ExportCSV from './ExportCSV';


/* const layoutR = {
    width: "100%",
    height: 200,
    margin: { r: 0, t: 0, b: 0, l: 0 },
    shapes: [
        //Rectangle reference to the axes
        {
            type: 'rect', xref: 'x', yref: 'y',
            x0: 0, y0: 0,
            x1: data.fajr, y1: 1,
            line: {
                color: 'rgb(55, 128, 191)',
                width: 3
            },
            fillcolor: 'rgba(55, 128, 191, 0.6)'
        },
    ]
} */

const ExpandedComponent = ({ data }) => {
    var x_fajr = parseInt(data.fajr.substring(0, 2)) + parseInt(data.fajr.substring(3, 5)) / 60;
    var x_fajr_end = parseInt(data.fajr_endtime.substring(0, 2)) + parseInt(data.fajr_endtime.substring(3, 5)) / 60;
    var x_duhr = parseInt(data.duhr.substring(0, 2)) + parseInt(data.duhr.substring(3, 5)) / 60;
    var x_asr = parseInt(data.asr.substring(0, 2)) + parseInt(data.asr.substring(3, 5)) / 60;
    var x_maghrib = parseInt(data.maghrib.substring(0, 2)) + parseInt(data.maghrib.substring(3, 5)) / 60;
    var x_isha = parseInt(data.isha.substring(0, 2)) + parseInt(data.isha.substring(3, 5)) / 60;

    var x0s = [0, x_fajr, x_duhr, x_asr, x_maghrib, x_isha, x_fajr];
    var x1s = [x_fajr, x_fajr_end, x_asr, x_maghrib, x_isha, 24, x_maghrib];
    var y0s = [0, 0, 0, 0, 0, 0, -0.10];
    var y1s = [1, 1, 1, 1, 1, 1, -0.35];
    var cols = ['rgb(150,150,150)', 'rgb(0,255,255)', 'rgb(255, 255, 0)', 'rgb(251, 177, 23)', 'rgb(238,130,238)', 'rgb(150,150,150)', 'rgb(150,255,150)'];
    var fills = ['rgb(150,150,150,0.6)', 'rgb(50,255,255, 0.6)', 'rgb(255, 255, 0, 0.6)', 'rgb(251, 177, 23, 0.6)', 'rgb(238,130,238,0.6)', 'rgb(150,150,150,0.6)', 'rgb(150,255,150,0.6)'];
    var is = [0, 1, 2, 3, 4, 5, 6];

    var shapes = [];
    is.forEach((i, ix) => {
        shapes.push({
            type: 'rect', xref: 'x', yref: 'y',
            x0: x0s[i], y0: y0s[i],
            x1: x1s[i], y1: y1s[i],
            line: {
                color: cols[i],
                width: 3
            },
            fillcolor: fills[i],
            layer: "below",
        })
    })

    var trace1 = {
        x: [x_fajr, x_fajr, x_fajr_end, x_duhr, x_asr, x_maghrib, x_isha, x_maghrib],
        y: [-0.15, 1.1, 0.9, 1.1, 0.9, 1.1, 0.9, -0.15],
        //text: ['faste starter', data.fajr, data.fajr_endtime, data.duhr, data.asr, data.maghrib, data.isha, 'faste slutter'],
        text: [data.fajr, data.fajr, data.fajr_endtime, data.duhr, data.asr, data.maghrib, data.isha, data.maghrib],
        hovertext: ['Faste starter<br>' + data.fajr, 'Fajr starter<br>' + data.fajr, 'Fajr slutter<br>' + data.fajr_endtime,
        'Duhr starter<br>' + data.duhr, 'Asr starter<br>' + data.asr, 'Maghrib starter<br>' + data.maghrib, 'Isha starter<br>' + data.isha, 'Faste slutter<br>' + data.maghrib],
        mode: 'text',
        textposition: 'bottom',
        textfont: {
            family: 'Courier New, monospace',
            size: 10,
            color: '#000000'
        },
        //hovertemplate: '<b>%{text}</b>',
        hovertemplate: '<b>%{hovertext}</b>',
    };

    var x_midnight = parseInt(data.muntasafallayl_midnight.substring(0, 2)) + parseInt(data.muntasafallayl_midnight.substring(3, 5)) / 60;
    var x_noon = parseInt(data.istiwa_noon.substring(0, 2)) + parseInt(data.istiwa_noon.substring(3, 5)) / 60;
    var x_nightfal_13 = parseInt(data.laylat_falakia_one3rd.substring(0, 2)) + parseInt(data.laylat_falakia_one3rd.substring(3, 5)) / 60;
    var x_nightfal_23 = parseInt(data.laylat_falakia_two3rd.substring(0, 2)) + parseInt(data.laylat_falakia_two3rd.substring(3, 5)) / 60;
    var x_1x = parseInt(data.shadow_1x.substring(0, 2)) + parseInt(data.shadow_1x.substring(3, 5)) / 60;
    var x_2x = parseInt(data.shadow_2x.substring(0, 2)) + parseInt(data.shadow_2x.substring(3, 5)) / 60;

    var trace2 = {
        x: [x_midnight, x_midnight, null, x_noon, x_noon, null, x_nightfal_13, x_nightfal_13, null, x_nightfal_23, x_nightfal_23, null, x_1x, x_1x, null, x_2x, x_2x],
        y: [0, 1.7, null, 0, 1.7, null, 0, 1.5, null, 0, 1.3, null, 0, 1.5, null, 0, 1.3],
        text: ['', 'midnatt', '', '', 'middagstid', '', '', 'natt-falak1/3', '', '', 'natt-falak2/3', '', '', '1x-skygge', '', '', '2x-skygge'],
        hovertext: ['', 'midnatt<br>' + data.muntasafallayl_midnight, '', '', 'middagstid<br>' + data.istiwa_noon, '',
            '', 'natt-falak1/3<br>' + data.laylat_falakia_one3rd, '', '', 'natt-falak2/3<br>' + data.laylat_falakia_two3rd, '',
            '', '1x-skygge<br>' + data.shadow_1x + '<br>Varighet fra Duhr = ' + (x_1x - x_duhr).toFixed(1) + ' timer', '',
            '', '2x-skygge<br>' + data.shadow_2x + '<br>Varighet fra Duhr = ' + (x_2x - x_duhr).toFixed(1) + ' timer'],
        mode: 'lines+text',
        color: 'black',
        line: { dash: 'solid ', color: 'black', width: 1 },
        type: 'scatter',
        textposition: 'top',
        textfont: {
            //family: 'Courier New, monospace',
            size: 10,
            color: '#000000'
        },
        hovertemplate: '<b>%{hovertext}</b>',
    };

    //console.log(x_nightfal_13, data.laylat_falakia_one3rd)

    /* var xas = [x_fajr, x_fajr_end, x_duhr, x_asr, x_maghrib, x_isha];
    var yas = [1.1, 0.9, 1.1, 0.9, 1.1, 0.9, 1.1]
    var texts = ['Fajr', 'Fajr slutt', 'Duhr', 'Asr', 'Maghrib', 'Isha'];
    var ans = [0, 1, 2, 3, 4, 5]; */
    var annotes = [];
    // ans.map((i, ix) => {
    //     annotes.push({
    //         type: 'rect', xref: 'x', yref: 'y',
    //         x: xas[i],
    //         y: yas[i],
    //         text: texts[i],
    //         textangle: 0,
    //         align: 'left',
    //         showarrow: true,
    //         ax: 10,
    //         ay: -40,
    //         arrowhead: 1,
    //         arrowcolor: '#636363',
    //         arrowwidth: 1,
    //         /*arrowcolor: 3,*/
    //     })
    // })

    var xas2 = [x_fajr, x_duhr, x_asr, x_maghrib, x_isha, x_fajr_end];
    var yas2 = [0.325, 0.125, 0.325, 0.125, 0.325, -0.225];
    var texts2 = ['FAJR', 'DUHR', 'ASR', 'MAGHRIB', 'ISHA', 'FASTE = ' + (x_maghrib - x_fajr).toFixed(1) + ' timer'];
    var texts3 = ['Fajr varighet = ' + (x_fajr_end - x_fajr).toFixed(1) + ' timer',
    'Duhr varighet = ' + (x_asr - x_duhr).toFixed(1) + ' timer',
    'Asr varighet = ' + (x_maghrib - x_asr).toFixed(1) + ' timer',
    'Maghrib varighet = ' + (x_isha - x_maghrib).toFixed(1) + ' timer',
    'Isha varighet til midnatt = ' + (24 - x_isha + x_midnight).toFixed(1) + ' timer',
    'Faste varighet = ' + (x_maghrib - x_fajr).toFixed(1) + ' timer'];
    var ans2 = [0, 1, 2, 3, 4, 5];

    ans2.forEach((i, ix) => {
        annotes.push({
            type: 'rect', xref: 'x', yref: 'y',
            x: xas2[i],
            y: yas2[i],
            text: texts2[i],
            hovertext: texts3[i],
            textangle: 0,
            xanchor: 'left',
            showarrow: false,
            font: {
                family: 'Courier New, monospace',
                size: 9,
                color: '#ffffff'
            },
            bordercolor: '#c7c7c7',
            borderwidth: 1,
            borderpad: 1,
            bgcolor: '#000000',
        })
    })

    return (
        <div>

            <Plot data={[trace1, trace2]} layout={{
                width: "100%",
                height: 200,
                margin: { r: 0, t: 0, b: 0, l: 0 },
                xaxis: {
                    range: [-1, 25],
                    showgrid: false,
                    showticklabels: false,
                    visible: false,
                },
                yaxis: {
                    range: [-0.5, 2.5],
                    showgrid: false,
                    showticklabels: false,
                    //visible: false,
                },
                shapes: shapes,
                annotations: annotes,
                plot_bgcolor: "white",
                showlegend: false,
            }} />
            {/* {data.fajr}
            {parseInt(data.fajr.substring(0, 2)) + parseInt(data.fajr.substring(3, 5)) / 60}
            TEST */}
            {/* {x_fajr}
            {JSON.stringify(data, null, 2)} */}
        </div>
    )
};

function TimeTable() {
    var gettoday = new Date();
    let dayWeek = gettoday.getDay();
    var dd = String(gettoday.getDate()).padStart(2, '0');
    var mm = String(gettoday.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = gettoday.getFullYear();

    //var mmInt = parseInt(mm) - 1;

    //const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const weekday = ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"];
    const weekdayshort = ["søn", "man", "tir", "ons", "tor", "fre", "lør"];
    const monthText = ["januar", "februar", "mars", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "desember"];

    //const [today, setToday] = useState(gettoday);
    const [loclist, setLoclist] = useState();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([])
    const [dataMonthly, setDataMonthly] = useState([])
    const [mmNum, setMmNum] = useState(mm)
    const [locid, setLocid] = useState(181)
    const [locname, setLocname] = useState('Oslo')
    const [lat, setLat] = useState("59.913263")

    const headers = {
        'Api-Token': process.env.REACT_APP_API_KEY
    };

    // console.log(process.env.REACT_APP_API_KEY, headers)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.bonnetid.no/locations/', { headers });
                const result = await response.json();

                const dataWithId = result.map((item, index) => ({
                    ...item,
                    id: item.pk,
                }));

                setLoclist(dataWithId);
                // console.log(dataWithId);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []); // The empty dependency array ensures that this effect runs only once on mount


    function stringToDate(_date, _format, _delimiter) {
        var formatLowerCase = _format.toLowerCase();
        var formatItems = formatLowerCase.split(_delimiter);
        var dateItems = _date.split(_delimiter);
        var monthIndex = formatItems.indexOf("mm");
        var dayIndex = formatItems.indexOf("dd");
        var yearIndex = formatItems.indexOf("yyyy");
        var month = parseInt(dateItems[monthIndex]);
        month -= 1;
        var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
        return formatedDate;
    }

    function changeMonth(e) {

        //console.log(e.target.value, mm, dd)
        // console.log(mmNum, e.target.value)
        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await axios.get('https://api.bonnetid.no//prayertimes/' + locid + '/' + String(yyyy) + '/' + e.target.value, { headers });
                var responseNew = response.map((v) => ({ ...v, dateNorsk: v.date.substring(0, 5).replace('-', '.'), day: weekdayshort[stringToDate(v.date, "dd-mm-yyyy", "-").getDay()] }))
                //setDataMonthly(response);
                setDataMonthly(responseNew);
                //console.log(response)
                //.log(responseNew)
                //console.log(new Date("2022-03-02").getDay())
                //console.log(stringToDate("24-03-2022", "dd-mm-yyyy", "-").getDay())
            } catch (error) {
                console.log(error)
                //console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();
        setMmNum(e.target.value)
    }

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        //console.log(string, results)
    }

    const handleOnHover = (result) => {
        // the item hovered
        //console.log(result)
    }

    /* const onItemClick = useCallback(event => {        
        const { data: response } = await axios.get('https://api.bonnetid.no//prayertimes/' + locid + '/' + String(yyyy) + '/' + mmNum + '/' + dd, { headers });
                //setData(response);
                setData(response);
      }, [data]); */

    const handleOnSelect = (item) => {
        // the item selected
        // console.log(item)
        // setLocid(item.id)
        // setLocname(item.name)
        // setLat(item.lat)

        setLocid(item.pk)
        setLocname(item.kommune)
        setLat(item.lat)

        const fetchDataMonthly = async () => {
            //setLoading(true);
            try {
                //const { data: response } = await axios.get('https://api.bonnetid.no//prayertimes/' + locid + '/' + String(yyyy) + '/' + mmNum, { headers });
                //const { data: response } = await axios.get('https://api.bonnetid.no//prayertimes/' + item.id + '/' + String(yyyy) + '/' + mmNum, { headers });
                const { data: response } = await axios.get('https://api.bonnetid.no//prayertimes/' + item.pk + '/' + String(yyyy) + '/' + mmNum, { headers });
                var responseNew = response.map((v) => ({ ...v, dateNorsk: v.date.substring(0, 5).replace('-', '.'), day: weekdayshort[stringToDate(v.date, "dd-mm-yyyy", "-").getDay()] }))
                //setDataMonthly(response);
                setDataMonthly(responseNew);

                //console.log(response)
                //console.log(responseNew)
                //console.log(new Date("2022-03-02").getDay())
                //console.log(stringToDate("24-03-2022", "dd-mm-yyyy", "-").getDay())
            } catch (error) {
                //console.log(error)
                //console.error(error.message);
            }
            //setLoading(false);
        }

        fetchDataMonthly();

        const fetchData = async () => {
            //setLoading(true);
            try {
                //const { data: response } = await axios.get('https://api.bonnetid.no//prayertimes/' + locid + '/' + String(yyyy) + '/' + mmNum + '/' + dd, { headers });
                //const { data: response } = await axios.get('https://api.bonnetid.no//prayertimes/' + item.id + '/' + String(yyyy) + '/' + mmNum + '/' + dd, { headers });
                const { data: response } = await axios.get('https://api.bonnetid.no//prayertimes/' + item.pk + '/' + String(yyyy) + '/' + mmNum + '/' + dd, { headers });
                //setData(response);
                setData(response);
                return response;
                //console.log(response)
            } catch (error) {
                console.log(error)
                //console.error(error.message);
            }
            //setLoading(false);
        }

        setData(fetchData());

    }

    const handleOnFocus = () => {
        //console.log('Focused')
    }

    const formatResult = (item) => {
        //console.log(item.name);
        //console.log(item.id);
        return (
            <>


                {/* <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span> */}
                <span style={{ display: 'block', textAlign: 'left' }}>{item.kommune}</span>
                <span style={{ display: 'block', textAlign: 'left' }}><small>{item.lat}&deg;N, {item.lon}&deg;E</small></span>


            </>
        )
        /*         return (
                    <div className="result-wrapper">
                        <span className="result-span">id: {item.id}</span>
                        <span className="result-span">name: {item.name}</span>
                    </div>
                ); */
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await axios.get('https://api.bonnetid.no//prayertimes/' + locid + '/' + String(yyyy) + '/' + mmNum + '/' + dd, { headers });
                setData(response);
                //console.log(response)
            } catch (error) {
                console.log(error)
                //console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await axios.get('https://api.bonnetid.no//prayertimes/' + locid + '/' + String(yyyy) + '/' + mmNum, { headers });
                var responseNew = response.map((v) => ({ ...v, dateNorsk: v.date.substring(0, 5).replace('-', '.'), day: weekdayshort[stringToDate(v.date, "dd-mm-yyyy", "-").getDay()] }))
                //setDataMonthly(response);
                setDataMonthly(responseNew);
                //console.log(response)
                //console.log(responseNew)
                //console.log(new Date("2022-03-02").getDay())
                //console.log(stringToDate("24-03-2022", "dd-mm-yyyy", "-").getDay())
            } catch (error) {
                console.log(error)
                //console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();
    }, []);

    const columns = [
        {
            name: 'Dato',
            selector: row => row.dateNorsk,
        }, {
            name: 'Dag',
            selector: row => row.day,
        },
        {
            name: 'Fajr',
            selector: row => row.fajr,
        },
        {
            name: 'Fajr slutt',
            selector: row => row.fajr_endtime,
        },
        {
            name: 'Duhr',
            selector: row => row.duhr,
        },
        {
            name: 'Asr',
            selector: row => row.asr_wusta_noon_sunset,
        },
        {
            name: 'Maghrib',
            selector: row => row.maghrib,
        },
        {
            name: 'Isha',
            selector: row => row.isha,
        },
    ];

    const columnsSouth = [
        {
            name: 'Dato',
            selector: row => row.dateNorsk,
            minWidth: "50px", compact: true, center: true,
        }, {
            name: 'Dag',
            selector: row => row.day,
            minWidth: "50px", compact: true, center: true,
        },
        {
            name: 'Morgengry16°',
            selector: row => row.fajr_sadiq,
            minWidth: "50px", compact: true, center: true,
        },
        {
            name: 'Fajr',
            selector: row => row.fajr,
            minWidth: "50px", compact: true, center: true,
            style: { color: "blue" }
        },
        {
            name: 'Fajr slutt',
            selector: row => row.fajr_endtime,
            minWidth: "50px", compact: true, center: true,
            style: { color: "blue" }
        },
        {
            name: 'Duhr',
            selector: row => row.duhr,
            minWidth: "50px", compact: true, center: true,
            style: { color: "blue" }
        },
        {
            name: 'Asr',
            selector: row => row.asr,
            minWidth: "50px", compact: true, center: true,
            style: { color: "blue" }
        },
        {
            name: '1x-skygge',
            selector: row => row.asr_1x_shadow,
            minWidth: "50px", compact: true, center: true,
        },
        {
            name: '2x-skygge',
            selector: row => row.asr_2x_shadow,
            minWidth: "50px", compact: true, center: true,
        },
        {
            name: 'Maghrib',
            selector: row => row.maghrib,
            minWidth: "50px", compact: true, center: true,
            style: { color: "blue" }
        },
        {
            name: 'Isha',
            selector: row => row.isha,
            minWidth: "50px", compact: true, center: true,
            style: { color: "blue" }
        },
        {
            name: 'Kveldsgry 15°',
            selector: row => row.shafaqal_ahmar_end_redlight,
            minWidth: "50px", compact: true, center: true,
        },
        {
            name: 'Midnatt',
            selector: row => row.muntasafallayl_midnight,
            minWidth: "50px", compact: true, center: true,
        },
    ];

    /* Morgengry 16° = fajr_sadiq
    Fajr = fajr
    Fajr slutt = fajr_endtime
 
    Istiwa = istiwa_noon
    Duhr = duhr
    Asr = asr
    1x skygge = shadow_1x
    2x skygge = shadow_2x
    Asr slutt = ghrub_sunset
 
    Maghrib = maghrib
    Isha = isha
    Kveldsgry 15° = shafaqal_ahmar_end_redlight
 
    Midnatt = muntasafallayl_midnight */

    const conditionalRowStyles = [
        {
            when: row => row.day === "fre",
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        }, {
            when: row => row.dateNorsk === dd + "." + mm,
            style: {
                backgroundColor: 'rgba(228, 228, 228, 0.9)',
                color: 'black',
                fontWeight: 'bold',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ];



    return (
        <div>
            <Container>
                <Row>
                    {/* <Col md={3} className="bg-light vh-100"> */}
                    <Col md={3} className="bg-light" style={{ height: "1000px" }}>
                        <a href="https://www.irn.no/" rel="noopener noreferrer">
                            <Image src={logo} style={{ height: "100px" }} className="m-3"></Image>
                        </a>
                        <Card>
                            <Card.Title className="pt-3 pb-3 mb-0 bg-dark text-white">
                                {/* {locname} | {lat} */}
                                <Row>
                                    <Col style={{ textAlign: "right" }}>{locname}</Col>
                                    <Col style={{ textAlign: "left" }}>{lat}</Col>
                                </Row>
                            </Card.Title>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="bg-secondary text-white">
                                    <Row>
                                        <Col style={{ textAlign: "right" }}><h5>{weekday[dayWeek]}</h5></Col>
                                        <Col style={{ textAlign: "left" }}><h5>{data.date}</h5></Col>
                                    </Row>
                                </ListGroup.Item>
                                {parseFloat(lat) < 90//60.56
                                    ?
                                    <div>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Morgengry 16°</Col><Col style={{ textAlign: "left" }}><h5>{data.fajr_sadiq}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Fajr</Col><Col style={{ textAlign: "left" }}><h5>{data.fajr}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Fajr slutt</Col><Col style={{ textAlign: "left" }}><h5>{data.fajr_endtime}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Istiwa</Col><Col style={{ textAlign: "left" }}><h5>{data.istiwa_noon}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Duhr</Col><Col style={{ textAlign: "left" }}><h5>{data.duhr}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Asr</Col><Col style={{ textAlign: "left" }}><h5>{data.asr}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>1x-skygge</Col><Col style={{ textAlign: "left" }}><h5>{data.asr_1x_shadow}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>2x-skygge</Col><Col style={{ textAlign: "left" }}><h5>{data.asr_2x_shadow}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Maghrib</Col><Col style={{ textAlign: "left" }}><h5>{data.maghrib}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Isha</Col><Col style={{ textAlign: "left" }}><h5>{data.isha}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Kveldsgry 15°</Col><Col style={{ textAlign: "left" }}><h5>{data.shafaqal_ahmar_end_redlight}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Midnatt</Col><Col style={{ textAlign: "left" }}><h5>{data.muntasafallayl_midnight}</h5></Col></Row></ListGroup.Item>
                                    </div>
                                    :
                                    <div>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Fajr</Col><Col style={{ textAlign: "left" }}><h5>{data.fajr}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Fajr slutt</Col><Col style={{ textAlign: "left" }}><h5>{data.fajr_endtime}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Duhr</Col><Col style={{ textAlign: "left" }}><h5>{data.duhr}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Asr-Wusta</Col><Col style={{ textAlign: "left" }}><h5>{data.asr_wusta_noon_sunset}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Maghrib</Col><Col style={{ textAlign: "left" }}><h5>{data.maghrib}</h5></Col></Row></ListGroup.Item>
                                        <ListGroup.Item><Row><Col style={{ textAlign: "right" }}>Isha</Col><Col style={{ textAlign: "left" }}><h5>{data.isha}</h5></Col></Row></ListGroup.Item>
                                    </div>

                                }
                            </ListGroup>
                        </Card>
                        {/* <div>
                            <Button variant="link" onClick={() => window.open("/map", "_blank")}>Map</Button>
                        </div> */}
                        <div className="mt-3 mb-1">
                            Velg en kommune:
                            <ReactSearchAutocomplete
                                items={loclist}//{Locations}
                                onSearch={handleOnSearch}
                                onHover={handleOnHover}
                                onSelect={handleOnSelect}
                                onFocus={handleOnFocus}
                                formatResult={formatResult}
                                fuseOptions={{ shouldSort: true, keys: ["kommune"] }}//{{ shouldSort: true, keys: ["name"] }}
                                maxResults={3}
                                autoFocus
                                style={{ zIndex: "1" }}
                            //fuseOptions={{ [shouldSort = True, keys = "name"]}}
                            />
                        </div>
                    </Col>
                    <Col md={9}>
                        <Card>
                            <Card.Title className="pt-3 pb-1 mb-0 bg-dark text-white">
                                <Row>
                                    <Col style={{ textAlign: "left", marginLeft: "20px" }}>
                                        <h5>{locname.toUpperCase()}</h5>
                                    </Col>
                                    <Col>
                                        <ExportCSV
                                            locid={locid}
                                            yyyy={yyyy}
                                            mmNum={mmNum}
                                            locname={locname}
                                        />
                                    </Col>
                                    <Col style={{ textAlign: "right", marginRight: "20px" }} >
                                        <h5>{monthText[parseInt(mmNum) - 1]} {yyyy}</h5>
                                    </Col>
                                </Row>
                            </Card.Title>
                        </Card>
                        <ButtonToolbar aria-label="Toolbar with button groups">
                            <ButtonGroup className="w-100" aria-label="First group" size="sm" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Button variant={mmNum === "01" ? "primary" : "secondary"} onClick={changeMonth} value="01">JANUAR</Button>
                                <Button variant={mmNum === "02" ? "primary" : "secondary"} onClick={changeMonth} value="02">FEBRUAR</Button>
                                <Button variant={mmNum === "03" ? "primary" : "secondary"} onClick={changeMonth} value="03">MARCH</Button>
                                <Button variant={mmNum === "04" ? "primary" : "secondary"} onClick={changeMonth} value="04">APRIL</Button>
                                <Button variant={mmNum === "05" ? "primary" : "secondary"} onClick={changeMonth} value="05">MAI</Button>
                                <Button variant={mmNum === "06" ? "primary" : "secondary"} onClick={changeMonth} value="06">JUNI</Button>
                                <Button variant={mmNum === "07" ? "primary" : "secondary"} onClick={changeMonth} value="07">JULI</Button>
                                <Button variant={mmNum === "08" ? "primary" : "secondary"} onClick={changeMonth} value="08">AUGUST</Button>
                                <Button variant={mmNum === "09" ? "primary" : "secondary"} onClick={changeMonth} value="09">SEPTEMBER</Button>
                                <Button variant={mmNum === "10" ? "primary" : "secondary"} onClick={changeMonth} value="10">OKTOBER</Button>
                                <Button variant={mmNum === "11" ? "primary" : "secondary"} onClick={changeMonth} value="11">NOVEMBER</Button>
                                <Button variant={mmNum === "12" ? "primary" : "secondary"} onClick={changeMonth} value="12">DESEMBER</Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                        {parseFloat(lat) < 90//< 60.56
                            ? <DataTable
                                conditionalRowStyles={conditionalRowStyles}
                                fixedHeader
                                columns={columnsSouth}
                                data={dataMonthly}
                                dense
                                expandableRows
                                expandableRowsComponent={ExpandedComponent}
                            //pagination
                            //selectableRows
                            />
                            : <DataTable
                                conditionalRowStyles={conditionalRowStyles}
                                fixedHeader
                                columns={columns}
                                data={dataMonthly}
                                //dense
                                expandableRows
                                expandableRowsComponent={ExpandedComponent}
                                expandOnRowClicked
                            //pagination
                            //selectableRows
                            />
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TimeTable;